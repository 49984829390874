<template>
  <div class="container">
    <div class="main">
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <div class="player listmain kl8">
        <!-- 数字-->
        <div class="data ">
          <h3>正码</h3>
          <ul class="custom_ul">
            <!-- :class="item.selected ? 'selected' : ''" -->
            <li :class="item.selected?'selected':''" @click="setCurrentSelected(item)" v-for="item in rowsData[0].arr" :key="item.ResultID">
              <ol class="td_name" style="width:35px">
                <div :class="Number(item.label)>40?'senseball':'greyball'">{{item.label}}</div>
              </ol>
              <ol class="td_rate">
                <!-- item.Odds -->
                {{ item.Odds}}
              </ol>
              <ol class="td_cash">
                <input type="number" @click.stop="showOrHideCheck($event, item)" v-model="item.money" />
                <div class="quick-check" v-if="item.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(item, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(item)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>
          </ul>
        </div>

      </div>
      <yushe />
    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist">
            <li>
              <span>1-2【龙】</span>
              <b class="blue">10期</b>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import { zhengxiaoData } from "../data";
import mixins from "../mixins/mainMixins";
export default {
  name: "",
  props: [""],
  mixins: [mixins],
  data() {
    return {
      groupnames:'zm',
      changelongActive: "1",
      kaiActive: "1",
      rowsData: [{ title: "正码", arr: [] }],
      //
    };
  },
  computed: {},
  components: {
    topTimer,
    yushe,
  },
  watch: {
    // 判断是否可以点击确定提交按钮
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title: item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                if (ele.label == "特码包三") {
                  obj.num = this.num1 + "," + this.num2 + "," + this.num3;
                }
                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        let labelArr = [];
        for (let index = 1; index < 81; index++) {
          labelArr.push(index);
        }

        //正码
        let arr1 = this.filterData(resAArr, 1787, 1866, labelArr);
        this.rowsData[0].arr = arr1;

        this.$forceUpdate();
      },
    },
  },
  created() {},

  mounted() {},

  methods: {
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style scoped>
.selected3 {
  position: relative;
  left: 100px;
  /* height: 26px; */
  display: flex;
  justify-content: center;
}
.listmain > .data > ul.custom3 li:last-of-type .td_name {
  width: 100px;
}
.listmain > .data > ul.custom3 li:last-of-type {
  width: 100%;
}
.listmain > .data > ul.custom3 li {
  width: 25%;
}
.listmain > .data > ul.custom20 li {
  width: 20%;
}
.listmain > .data > ul.custom_ul1 li {
  height: 26px;
  max-height: 26px;
}
.listmain > .data > ul.custom_ul1 {
  width: 100%;
  height: 52px;
  min-height: 52px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: none;
  /* align-items: flex-start; */
}
.listmain > .data ul.custom_ul li {
  width: 25%;
  height: 30px;
}
.listmain > .data ul.custom_ul {
  width: 100%;
  height: 600px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: none;
}
.listmain .data ul li {
  width: 25%;
}

.listmain div.c2003 ul li {
  width: 50%;
}
.listmain > .data > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
.listmain > .data > h3 {
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-weight: 700;
  font-size: 13px;
}
.listmain div.c2003 {
  width: 100%;
}
.listmain .data {
  width: 100%;
}
.listmain > .data {
  margin-top: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.listmain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  width: 100%;
  clear: both;
}
</style>
